
































































import Vue from 'vue';
import Component from 'vue-class-component';
import { RejectResponse } from 'common-modules/src/store/moduleStore';
import { SelectOption } from 'common-modules/src/components/CommonFormElement.vue';
import JwlButton from '@/components/JwlButton.vue';
import BackBar from '@/components/BackBar.vue';

const IconArrowLeft = () => import('common-modules/src/assets/fa-icons/solid/arrow-left.svg');
const IconArrowRight = () => import('common-modules/src/assets/fa-icons/solid/arrow-right.svg');
const CommonError = () => import('common-modules/src/components/CommonError.vue');

const STATUS_INITIAL = 'initial';
const STATUS_GENERATING = 'generating';
const STATUS_GENERATED = 'generated';
const STATUS_ERROR = 'error';

@Component({
  components: {
    BackBar,
    IconArrowLeft,
    IconArrowRight,
    CommonError,
    JwlButton,
  },
})
export default class JwlProgrammeCertificateExport extends Vue {
  selectedUniversity = '';
  university: any | null = null;
  error: RejectResponse | null = null;
  downloadUrl = '';
  loaded = false;
  state = STATUS_INITIAL;

  generate (): void {
    if ([STATUS_INITIAL, STATUS_ERROR].includes(this.state)) {
      this.state = STATUS_GENERATING;
      this.$store.dispatch('getData', `programme-certificate/generate/${this.$route.params.programme}/${this.selectedUniversity}`)
        .then((data) => {
          this.downloadUrl = data.url;
          this.state = STATUS_GENERATED;
          window.location.assign(data.url);
        })
        .catch((e) => {
          this.error = e;
          this.state = STATUS_ERROR;
        });
    }
  }

  get universityFieldOption (): SelectOption[] {
    const universityOptions: SelectOption[] = [];
    if (this.university) {
      this.university.universities.forEach((originUniversity: any) => {
        universityOptions.push({
          id: originUniversity.id.toString(),
          label: originUniversity.name,
        });
      });
    }
    return universityOptions;
  }

  mounted (): void {
    this.$store.dispatch('getData', `programme-certificate/overview/${this.$route.params.programme}`)
      .then((data) => {
        this.university = data;
        if (data.university) {
          this.selectedUniversity = data.university.toString();
        }
        this.loaded = true;
      })
      .catch((e) => {
        this.error = e;
      });
  }
}
